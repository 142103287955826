const definition = {
  columnOrder: {
    "DOL Reference": 1,
    "Voltage Phase": 2,
    "Operating Voltage": 3,
    "Motor Power (kW)": 4,
    "Motor Power (hp)": 5,
    "Coil Voltage": 6
  },
  data: [
    {
      "DOL Reference": "LE1M35B705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35B722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "24 VAC"
    },
    {
      "DOL Reference": "LE1M35F705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35F722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "110 VAC"
    },
    {
      "DOL Reference": "LE1M35M705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35M722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "220 VAC"
    },
    {
      "DOL Reference": "LE1M35P705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35P722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "230 VAC"
    },
    {
      "DOL Reference": "LE1M35U705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35U722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "240 VAC"
    },
    {
      "DOL Reference": "LE1M35Q705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35Q722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "380 VAC"
    },
    {
      "DOL Reference": "LE1M35V705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35V722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "400 VAC"
    },
    {
      "DOL Reference": "LE1M35N705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35N722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "415 VAC"
    },
    {
      "DOL Reference": "LE1M35R705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "220 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "230 VAC",
      "Motor Power (kW)": "3.7 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.12 kW",
      "Motor Power (hp)": "0.16 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "400 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R705",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.25 kW",
      "Motor Power (hp)": "0.35 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R706",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R707",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R708",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R710",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R712",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R714",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R716",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R721",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Coil Voltage": "440 VAC"
    },
    {
      "DOL Reference": "LE1M35R722",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "415 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Coil Voltage": "440 VAC"
    }
  ]
}

export default definition;
