const InductiveProximitySensor = {
  columnOrder: {
    "Mounting Type": 1,
    "Sensing Distance": 5,
    "Output Function": 2,
    "Connection": 3,
    "Case Type": 4,
  },
  data: [
    {
      "Sensor Reference": "XS1M12AB120",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2 mm",
      "Output Function": "4-20 mA",
      "Connection": "2 M Pre-cabled",
      "Case Type": "n/a"
    },
    {
      "Sensor Reference": "XS4P12AB120",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "4-20 mA",
      "Connection": "2 M Pre-cabled",
      "Case Type": "n/a"
    },
    {
      "Sensor Reference": "XS4P12AB110",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "0-10 V",
      "Connection": "2 M Pre-cabled",
      "Case Type": "n/a"
    },
    {
      "Sensor Reference": "XS1M18AB120",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "5 mm",
      "Output Function": "4-20 mA",
      "Connection": "2 M Pre-cabled",
      "Case Type": "n/a"
    },
    {
      "Sensor Reference": "XS4P18AB120",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "4-20 mA",
      "Connection": "2 M Pre-cabled",
      "Case Type": "n/a"
    },
    {
      "Sensor Reference": "XS4P18AB110",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "0-10 V",
      "Connection": "2 M Pre-cabled",
      "Case Type": "n/a"
    },
    {
      "Sensor Reference": "XS1M30AB120",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "10 mm",
      "Output Function": "4-20 mA",
      "Connection": "2 M Pre-cabled",
      "Case Type": "n/a"
    },
    {
      "Sensor Reference": "XS4P30AB120",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "4-20 mA",
      "Connection": "2 M Pre-cabled",
      "Case Type": "n/a"
    },
    {
      "Sensor Reference": "XS4P30AB110",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "0-10 V",
      "Connection": "2 M Pre-cabled",
      "Case Type": "n/a"
    },
    {
      "Sensor Reference": "XS108B3PAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3PAM8",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, PNP",
      "Connection": "M8 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3PAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3NAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3NAM8",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, NPN",
      "Connection": "M8 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3NAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3PBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3PBM8",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, PNP",
      "Connection": "M8 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3PBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3NBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3NBM8",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, NPN",
      "Connection": "M8 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3NBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3PAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3PAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3NAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3NAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3PBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3PBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3NBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3NBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3PAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3PAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3NAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3NAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3PBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3PBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3NBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3NBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3PAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3PAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3NAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3NAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3PBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3PBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3NBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3NBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS608B1PAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1PAM8",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, PNP",
      "Connection": "M8 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1PAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1NAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1NAM8",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, NPN",
      "Connection": "M8 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1NAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1PBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1PBM8",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, PNP",
      "Connection": "M8 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1PBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1NBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1NBM8",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, NPN",
      "Connection": "M8 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1NBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1PAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1PAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1NAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1NAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1PBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1PBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1NBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1NBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PAL01B",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "Remote Screw Terminal Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PAL01C",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "Remote EN 175301-803-A Connector ",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PAL01G",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "Remote M18 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1NAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1NAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1NAL01B",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "Remote Screw Terminal Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PBL01B",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, PNP",
      "Connection": "Remote Screw Terminal Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1NBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1NBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PAL01B",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "Remote Screw Terminal Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PAL01C",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "Remote EN 175301-803-A Connector ",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PAL01G",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "Remote M18 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1NAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1NAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1NAL01B",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, NPN",
      "Connection": "Remote Screw Terminal Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PBL01C",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, PNP",
      "Connection": "Remote EN 175301-803-A Connector ",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1NBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1NBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B3CAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS608B3CAL01M12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO",
      "Connection": "Remote M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS608B3CBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS608B3CBL01M12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC",
      "Connection": "Remote M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS612B3DAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS612B3DAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS612B3DBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS612B3DBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS618B3DAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS618B3DAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS618B3DBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS618B3DBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS630B3DAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS630B3DAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS630B3DBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS630B3DBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS608B1DAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1DAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1DBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1DBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1DAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1DAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1DBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1DBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1DAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1DAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1DBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1DBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1DAL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1DAM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1DBL2",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1DBM12",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4PAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4PAM8",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, PNP",
      "Connection": "M8 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4PAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4NAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4NAM8",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, NPN",
      "Connection": "M8 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4NAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4PBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4PBM8",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, PNP",
      "Connection": "M8 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4PBM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4NBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4NBM8",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, NPN",
      "Connection": "M8 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B4NBM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4PAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4PAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4NAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4NAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4PBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4PBM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4NBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4NBM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4PAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4PAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4NAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4NAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4PBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4PBM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4NBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4NBM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5PAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5PAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5NAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5NAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5PBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5PBM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5NBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS212B4PAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS212B4PAL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS212B4PAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS212B4NAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS212B4NAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS212B4PBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS212B4PBM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS212B4NBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS218B4PAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "16 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS218B4PAL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "16 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS218B4PAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "16 mm",
      "Output Function": "NO, PNP",
      "Connection": "M12 connector ",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS218B4NAL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "16 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS218B4NAM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "16 mm",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS218B4PBL2",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "16 mm",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS218B4PBM12",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "16 mm",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3PAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3NAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3PBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS108B3NBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3PAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3NAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3PBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS112B3NBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3PAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3NAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3PBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS118B3NBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3PAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3NAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3PBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS130B3NBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS608B1PAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1NAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1PBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1NBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1PAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1NAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1PBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1NBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1NAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1NBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1NAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1NBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1PAL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1NAL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1PBL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1NBL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1PAL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1NAL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1PBL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1NBL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PAL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1NAL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1PBL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1NBL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PAL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1NAL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1PBL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1NBL10",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B3CAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS608B3CBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS612B3DAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS612B3DBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS618B3DAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS618B3DBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS630B3DAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS630B3DBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Short Case"
    },
    {
      "Sensor Reference": "XS608B1DAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NO",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS608B1DBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "2.5 mm",
      "Output Function": "NC",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1DAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NO",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B1DBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "4 mm",
      "Output Function": "NC",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1DAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NO",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B1DBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "8 mm",
      "Output Function": "NC",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1DAL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NO",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B1DBL5",
      "Mounting Type": "Flush Mount",
      "Sensing Distance": "15 mm",
      "Output Function": "NC",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4PAL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4NAL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4PBL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4NBL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4PAL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4NAL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4PBL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4NBL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5PAL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5NAL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5PBL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5NBL5",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4PAL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NO, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4NAL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NO, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4PBL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NC, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS612B4NBL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "7 mm",
      "Output Function": "NC, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4PAL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NO, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4NAL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NO, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4PBL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NC, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS618B4NBL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "12 mm",
      "Output Function": "NC, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5PAL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NO, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5NAL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NO, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5PBL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NC, PNP",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    },
    {
      "Sensor Reference": "XS630B5NBL10",
      "Mounting Type": "Non Flush Mount",
      "Sensing Distance": "30 mm",
      "Output Function": "NC, NPN",
      "Connection": "10 M Pre-cabled",
      "Case Type": "Long Case"
    }
  ]
};

export default InductiveProximitySensor;
