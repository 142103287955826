
const definition = {
  columnOrder: {
    "Enclosure Reference": 1,
    "Height": 2,
    "Width": 3,
    "Depth": 4,
    "Door Type": 5
  },
  data: [
    {
      "Enclosure Reference": "NSYCRN22150",
      "Height": "200 mm",
      "Width": "200 mm",
      "Depth": "150 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN23150",
      "Height": "200 mm",
      "Width": "300 mm",
      "Depth": "150 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN252150",
      "Height": "250 mm",
      "Width": "200 mm",
      "Depth": "150 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN325150",
      "Height": "300 mm",
      "Width": "250 mm",
      "Depth": "150 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN325200",
      "Height": "300 mm",
      "Width": "250 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN33150",
      "Height": "300 mm",
      "Width": "300 mm",
      "Depth": "150 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN33200",
      "Height": "300 mm",
      "Width": "300 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN34200",
      "Height": "300 mm",
      "Width": "400 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN345150",
      "Height": "300 mm",
      "Width": "450 mm",
      "Depth": "150 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN43150",
      "Height": "400 mm",
      "Width": "300 mm",
      "Depth": "150 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN43200",
      "Height": "400 mm",
      "Width": "300 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN44200",
      "Height": "400 mm",
      "Width": "400 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN46250",
      "Height": "400 mm",
      "Width": "600 mm",
      "Depth": "250 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN46300",
      "Height": "400 mm",
      "Width": "600 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN54150",
      "Height": "500 mm",
      "Width": "400 mm",
      "Depth": "150 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN54200",
      "Height": "500 mm",
      "Width": "400 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN54250",
      "Height": "500 mm",
      "Width": "400 mm",
      "Depth": "250 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN55250",
      "Height": "500 mm",
      "Width": "500 mm",
      "Depth": "250 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN64150",
      "Height": "600 mm",
      "Width": "400 mm",
      "Depth": "150 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN64200",
      "Height": "600 mm",
      "Width": "400 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN64250",
      "Height": "600 mm",
      "Width": "400 mm",
      "Depth": "250 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN65150",
      "Height": "600 mm",
      "Width": "500 mm",
      "Depth": "150 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN65200",
      "Height": "600 mm",
      "Width": "500 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN65250",
      "Height": "600 mm",
      "Width": "500 mm",
      "Depth": "250 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN66200",
      "Height": "600 mm",
      "Width": "600 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN66250",
      "Height": "600 mm",
      "Width": "600 mm",
      "Depth": "250 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN66300",
      "Height": "600 mm",
      "Width": "600 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN68300",
      "Height": "600 mm",
      "Width": "800 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN75200",
      "Height": "700 mm",
      "Width": "500 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN75250",
      "Height": "700 mm",
      "Width": "500 mm",
      "Depth": "250 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN86200",
      "Height": "800 mm",
      "Width": "600 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN86250",
      "Height": "800 mm",
      "Width": "600 mm",
      "Depth": "250 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN86300",
      "Height": "800 mm",
      "Width": "600 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN88200",
      "Height": "800 mm",
      "Width": "800 mm",
      "Depth": "200 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN88300",
      "Height": "800 mm",
      "Width": "800 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN106250",
      "Height": "1000 mm",
      "Width": "600 mm",
      "Depth": "250 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN106300",
      "Height": "1000 mm",
      "Width": "600 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN108250",
      "Height": "1000 mm",
      "Width": "800 mm",
      "Depth": "250 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN108300",
      "Height": "1000 mm",
      "Width": "800 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG86400",
      "Height": "800 mm",
      "Width": "600 mm",
      "Depth": "400 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG810300D",
      "Height": "800 mm",
      "Width": "1000 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG812300D",
      "Height": "800 mm",
      "Width": "1200 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG106400",
      "Height": "1000 mm",
      "Width": "600 mm",
      "Depth": "400 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG108400",
      "Height": "1000 mm",
      "Width": "800 mm",
      "Depth": "400 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG1010300D",
      "Height": "1000 mm",
      "Width": "1000 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG1012300D",
      "Height": "1000 mm",
      "Width": "1200 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG1012400D",
      "Height": "1000 mm",
      "Width": "1200 mm",
      "Depth": "400 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG126300",
      "Height": "1200 mm",
      "Width": "600 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG126400",
      "Height": "1200 mm",
      "Width": "600 mm",
      "Depth": "400 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG128300",
      "Height": "1200 mm",
      "Width": "800 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG128400",
      "Height": "1200 mm",
      "Width": "800 mm",
      "Depth": "400 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG1210300D",
      "Height": "1200 mm",
      "Width": "1000 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG1210400D",
      "Height": "1200 mm",
      "Width": "1000 mm",
      "Depth": "400 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG1212300D",
      "Height": "1200 mm",
      "Width": "1200 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG1212400D",
      "Height": "1200 mm",
      "Width": "1200 mm",
      "Depth": "400 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRNG1410300D",
      "Height": "1400 mm",
      "Width": "1000 mm",
      "Depth": "300 mm",
      "Door Type": "Plain Door"
    },
    {
      "Enclosure Reference": "NSYCRN325150T",
      "Height": "300 mm",
      "Width": "250 mm",
      "Depth": "150 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN325200T",
      "Height": "300 mm",
      "Width": "250 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN33150T",
      "Height": "300 mm",
      "Width": "300 mm",
      "Depth": "150 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN33200T",
      "Height": "300 mm",
      "Width": "300 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN34200T",
      "Height": "300 mm",
      "Width": "400 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN43150T",
      "Height": "400 mm",
      "Width": "300 mm",
      "Depth": "150 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN43200T",
      "Height": "400 mm",
      "Width": "300 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN44200T",
      "Height": "400 mm",
      "Width": "400 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN46250T",
      "Height": "400 mm",
      "Width": "600 mm",
      "Depth": "250 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN46300T",
      "Height": "400 mm",
      "Width": "600 mm",
      "Depth": "300 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN54150T",
      "Height": "500 mm",
      "Width": "400 mm",
      "Depth": "150 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN54200T",
      "Height": "500 mm",
      "Width": "400 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN54250T",
      "Height": "500 mm",
      "Width": "400 mm",
      "Depth": "250 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN55250T",
      "Height": "500 mm",
      "Width": "500 mm",
      "Depth": "250 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN64150T",
      "Height": "600 mm",
      "Width": "400 mm",
      "Depth": "150 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN64200T",
      "Height": "600 mm",
      "Width": "400 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN64250T",
      "Height": "600 mm",
      "Width": "400 mm",
      "Depth": "250 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN65150T",
      "Height": "600 mm",
      "Width": "500 mm",
      "Depth": "150 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN65200T",
      "Height": "600 mm",
      "Width": "500 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN65250T",
      "Height": "600 mm",
      "Width": "500 mm",
      "Depth": "250 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN66200T",
      "Height": "600 mm",
      "Width": "600 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN66250T",
      "Height": "600 mm",
      "Width": "600 mm",
      "Depth": "250 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN66300T",
      "Height": "600 mm",
      "Width": "600 mm",
      "Depth": "300 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN68300T",
      "Height": "600 mm",
      "Width": "800 mm",
      "Depth": "300 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN75200T",
      "Height": "700 mm",
      "Width": "500 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN75250T",
      "Height": "700 mm",
      "Width": "500 mm",
      "Depth": "250 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN86200T",
      "Height": "800 mm",
      "Width": "600 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN86250T",
      "Height": "800 mm",
      "Width": "600 mm",
      "Depth": "250 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN86300T",
      "Height": "800 mm",
      "Width": "600 mm",
      "Depth": "300 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN88200T",
      "Height": "800 mm",
      "Width": "800 mm",
      "Depth": "200 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN88300T",
      "Height": "800 mm",
      "Width": "800 mm",
      "Depth": "300 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN106250T",
      "Height": "1000 mm",
      "Width": "600 mm",
      "Depth": "250 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN106300T",
      "Height": "1000 mm",
      "Width": "600 mm",
      "Depth": "300 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN108250T",
      "Height": "1000 mm",
      "Width": "800 mm",
      "Depth": "250 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRN108300T",
      "Height": "1000 mm",
      "Width": "800 mm",
      "Depth": "300 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRNG86400T",
      "Height": "800 mm",
      "Width": "600 mm",
      "Depth": "400 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRNG106400T",
      "Height": "1000 mm",
      "Width": "600 mm",
      "Depth": "400 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRNG108400T",
      "Height": "1000 mm",
      "Width": "800 mm",
      "Depth": "400 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRNG1010300T",
      "Height": "1000 mm",
      "Width": "1000 mm",
      "Depth": "300 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRNG126300T",
      "Height": "1200 mm",
      "Width": "600 mm",
      "Depth": "300 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRNG126400T",
      "Height": "1200 mm",
      "Width": "600 mm",
      "Depth": "400 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRNG128300T",
      "Height": "1200 mm",
      "Width": "800 mm",
      "Depth": "300 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "NSYCRNG128400T",
      "Height": "1200 mm",
      "Width": "800 mm",
      "Depth": "400 mm",
      "Door Type": "Glazed Door"
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    },
    {
      "Enclosure Reference": "",
      "Height": "",
      "Width": "",
      "Depth": "",
      "Door Type": ""
    }
  ]
}

export default definition;
