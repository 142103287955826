const definition = {
  columnOrder: {
    'Sensor Reference': 1,
    'Output Function': 2,
  },
  data: [
    {
      "Sensor Reference": "XX7V1A1NAM12",
      "Output Function": "NO, NPN"
    },
    {
      "Sensor Reference": "XX7V1A1PAM12",
      "Output Function": "NO, PNP"
    },
    {
      "Sensor Reference": "XX9V1A1C2M12",
      "Output Function": "4-20 mA"
    },
    {
      "Sensor Reference": "XX9V1A1F1M12",
      "Output Function": "0-10 V"
    }
  ]
}

export default definition;