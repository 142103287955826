const definition = {

  columnOrder: {
    "UPS Reference": 1,
    "UPS Voltage Type": 2,
    "UPS Rated Capacity": 3
  },
  data: [
    {
      "UPS Reference": "SRVS1KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "800 W"
    },
    {
      "UPS Reference": "SRVS1KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "800 W"
    },
    {
      "UPS Reference": "SRVS1KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "800 W"
    },
    {
      "UPS Reference": "SRVS1KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "800 W"
    },
    {
      "UPS Reference": "SRVS1KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "800 W"
    },
    {
      "UPS Reference": "SRVS1KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "800 W"
    },
    {
      "UPS Reference": "SRVS2KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "1600 W"
    },
    {
      "UPS Reference": "SRVS2KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "1600 W"
    },
    {
      "UPS Reference": "SRVS2KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "1600 W"
    },
    {
      "UPS Reference": "SRVS2KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "1600 W"
    },
    {
      "UPS Reference": "SRVS2KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "1600 W"
    },
    {
      "UPS Reference": "SRVS2KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "1600 W"
    },
    {
      "UPS Reference": "SRVS3KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "2400 W"
    },
    {
      "UPS Reference": "SRVS3KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "2400 W"
    },
    {
      "UPS Reference": "SRVS3KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "2400 W"
    },
    {
      "UPS Reference": "SRVS3KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "2400 W"
    },
    {
      "UPS Reference": "SRVS3KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "2400 W"
    },
    {
      "UPS Reference": "SRVS3KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "2400 W"
    },
    {
      "UPS Reference": "SRVS6KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "6000 W"
    },
    {
      "UPS Reference": "SRVS6KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "6000 W"
    },
    {
      "UPS Reference": "SRVS6KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "6000 W"
    },
    {
      "UPS Reference": "SRVS6KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "6000 W"
    },
    {
      "UPS Reference": "SRVS6KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "6000 W"
    },
    {
      "UPS Reference": "SRVS6KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "6000 W"
    },
    {
      "UPS Reference": "SRVS10KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "10000 W"
    },
    {
      "UPS Reference": "SRVS10KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "10000 W"
    },
    {
      "UPS Reference": "SRVS10KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "10000 W"
    },
    {
      "UPS Reference": "SRVS10KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "10000 W"
    },
    {
      "UPS Reference": "SRVS10KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "10000 W"
    },
    {
      "UPS Reference": "SRVS10KRIRK",
      "UPS Voltage Type": "AC",
      "UPS Rated Capacity": "10000 W"
    },
    {
      "UPS Reference": "Vancouver 240W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "240 W"
    },
    {
      "UPS Reference": "Vancouver 240W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "240 W"
    },
    {
      "UPS Reference": "Vancouver 240W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "240 W"
    },
    {
      "UPS Reference": "Vancouver 240W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "240 W"
    },
    {
      "UPS Reference": "Vancouver 240W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "240 W"
    },
    {
      "UPS Reference": "Vancouver 240W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "240 W"
    },
    {
      "UPS Reference": "Vancouver 480W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "480 W"
    },
    {
      "UPS Reference": "Vancouver 480W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "480 W"
    },
    {
      "UPS Reference": "Vancouver 480W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "480 W"
    },
    {
      "UPS Reference": "Vancouver 480W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "480 W"
    },
    {
      "UPS Reference": "Vancouver 480W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "480 W"
    },
    {
      "UPS Reference": "Vancouver 480W",
      "UPS Voltage Type": "DC",
      "UPS Rated Capacity": "480 W"
    }
  ]
}
export default definition;
