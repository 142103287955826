const definition = {
  columnOrder: {
    "Temperature Control Reference": 1,
    "SSR Reference": 2,
    "Supply Voltage": 3,
    "Modbus Communication": 4,
    "Output Voltage": 5,
    "Load Current": 6,
    "Heat Dissipation": 7,
    "Configuration Type": 8
  },
  data: [
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSM1D36BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSM1D312BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1D520BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1D530BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "30 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1D520BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1D530BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "30 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSM1A16BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSM1A112BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSM1A36BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSM1A312BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1A320BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1A320BDC2",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1A320BDC3",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1A335BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1A335BDC2",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1A335BDC3",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1A345BDC2",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "45 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1A345BDC3",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "45 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1A360BDC2",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "60 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSD1A360BDC3",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "60 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1D412BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1D425BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1D440BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "40 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1D412BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1D425BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1D440BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "40 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1A110BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "10 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1A125BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1A150BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "50 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1A175BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "75 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1A450BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "50 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNHU",
      "SSR Reference": "SSP1A475BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "75 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSM1D36BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSM1D312BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1D520BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1D530BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "30 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1D520BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1D530BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "30 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSM1A16BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSM1A112BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSM1A36BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSM1A312BD",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1A320BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1A320BDC2",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1A320BDC3",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1A335BDC1",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1A335BDC2",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1A335BDC3",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1A345BDC2",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "45 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1A345BDC3",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "45 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1A360BDC2",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "60 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSD1A360BDC3",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "60 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1D412BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1D425BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1D440BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "40 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1D412BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1D425BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1D440BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "40 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1A110BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "10 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1A125BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1A150BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "50 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1A175BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "75 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1A450BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "50 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNHU",
      "SSR Reference": "SSP1A475BDT",
      "Supply Voltage": "100-240 VAC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "75 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSM1D36BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSM1D312BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1D520BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1D530BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "30 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1D520BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1D530BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "30 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSM1A16BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSM1A112BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSM1A36BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSM1A312BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1A320BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1A320BDC2",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1A320BDC3",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1A335BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1A335BDC2",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1A335BDC3",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1A345BDC2",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "45 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1A345BDC3",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "45 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1A360BDC2",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "60 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSD1A360BDC3",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "60 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1D412BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1D425BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1D440BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "40 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1D412BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1D425BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1D440BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "40 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1A110BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "10 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1A125BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1A150BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "50 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1A175BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "75 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1A450BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "50 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUN1SNLU",
      "SSR Reference": "SSP1A475BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "Without Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "75 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSM1D36BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSM1D312BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1D520BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1D530BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "30 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1D520BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1D530BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "30 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSM1A16BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSM1A112BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSM1A36BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "6 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSM1A312BD",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "12 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1A320BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1A320BDC2",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1A320BDC3",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "20 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1A335BDC1",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1A335BDC2",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1A335BDC3",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "35 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1A345BDC2",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "45 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1A345BDC3",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "45 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1A360BDC2",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "60 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Screw Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSD1A360BDC3",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "60 A",
      "Heat Dissipation": "Embedded Heatsink",
      "Configuration Type": "Spring Contactor"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1D412BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1D425BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1D440BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?100 VDC",
      "Load Current": "40 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1D412BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "12 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1D425BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1D440BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "110-150 VDC",
      "Load Current": "40 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1A110BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "10 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1A125BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "25 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1A150BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "50 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1A175BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "?42 VAC",
      "Load Current": "75 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1A450BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "50 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    },
    {
      "Temperature Control Reference": "RTC48PUNCSNLU",
      "SSR Reference": "SSP1A475BDT",
      "Supply Voltage": "24 VAC/VDC",
      "Modbus Communication": "With Modbus",
      "Output Voltage": "48-600 VAC",
      "Load Current": "75 A",
      "Heat Dissipation": "Panel Mount",
      "Configuration Type": "Relay"
    }
  ]
}

export default definition;
