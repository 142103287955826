const definition = {
  columnOrder: {
    "Sensor Reference": 1,
    "Sensing Distance": 2,
    "Output Function": 3,
    "Connection": 4
  },
  data: [
    {
      "Sensor Reference": "XT218A1PCM12",
      "Sensing Distance": "8 mm",
      "Output Function": "NO/NC , PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XT218A1PAL2",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XT218A1NAL2",
      "Sensing Distance": "8 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XT218A1FAL2",
      "Sensing Distance": "8 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XT230A1PCM12",
      "Sensing Distance": "15 mm",
      "Output Function": "NO/NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XT230A1PAL2",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XT230A1NAL2",
      "Sensing Distance": "15 mm",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XT230A1FAL2",
      "Sensing Distance": "15 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XT230A1FBL2",
      "Sensing Distance": "15 mm",
      "Output Function": "NC",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XT232A1FAL2",
      "Sensing Distance": "20 mm",
      "Output Function": "NO",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XT232A1FBL2",
      "Sensing Distance": "20 mm",
      "Output Function": "NC",
      "Connection": "2 M Pre-cabled"
    }
  ]
}

export default definition;
