const definition = {
  columnOrder: {
    "MCCB Reference": 1,
    "Supply Voltage": 2,
    "Breaking Capacity": 3,
    "Current Rating": 4,
    "No of Poles": 5,
    "Connector Type": 6
  },
  data: [
    {
      "MCCB Reference": "LV426100",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426101",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426102",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426103",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426104",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426105",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426106",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426107",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426108",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426109",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426150",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426151",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426152",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426153",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426154",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426155",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426156",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426157",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426158",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426159",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426110",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426111",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426112",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426113",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426114",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426115",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426116",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426117",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426118",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426119",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426160",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426161",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426162",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426163",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426164",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426165",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426166",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426167",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426168",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426169",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426120",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426121",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426122",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426123",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426124",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426125",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426126",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426127",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426128",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426129",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426170",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426171",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426172",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426173",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426174",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426175",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426176",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426177",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426178",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426179",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "16 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426200",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426201",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426202",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426203",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426204",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426205",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426206",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426207",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426208",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426209",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426250",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426251",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426252",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426253",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426254",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426255",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426256",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426257",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426258",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426259",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426210",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426211",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426212",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426213",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426214",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426215",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426216",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426217",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426218",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426219",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426260",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426261",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426262",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426263",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426264",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426265",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426266",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426267",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426268",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426269",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426220",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426221",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426222",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426223",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426224",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426225",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426226",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426227",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426228",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426229",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426270",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426271",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426272",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426273",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426274",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426275",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426276",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426277",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426278",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426279",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426300",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426301",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426302",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426303",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426304",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426305",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426306",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426307",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426308",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426309",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426350",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426351",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426352",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426353",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426354",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426355",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426356",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426357",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426358",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426359",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426310",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426311",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426312",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426313",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426314",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426315",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426316",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426317",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426318",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426319",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426360",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426361",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426362",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426363",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426364",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426365",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426366",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426367",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426368",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426369",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426320",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426321",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426322",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426323",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426324",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426325",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426326",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426327",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426328",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426329",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426370",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426371",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426372",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426373",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426374",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426375",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426376",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426377",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426378",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426379",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "36 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426400",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426401",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426402",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426403",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426404",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426405",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426406",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426407",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426408",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426409",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426450",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426451",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426452",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426453",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426454",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426455",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426456",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426457",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426458",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426459",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426410",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426411",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426412",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426413",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426414",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426415",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426416",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426417",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426418",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426419",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426460",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426461",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426462",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426463",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426464",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426465",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426466",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426467",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426468",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426469",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426420",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426421",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426422",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426423",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426424",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426425",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426426",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426427",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426428",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426429",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426470",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426471",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426472",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426473",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426474",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426475",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426476",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426477",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426478",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426479",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426500",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426501",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426502",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426503",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426504",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426505",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426506",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426507",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426508",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426509",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426550",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426551",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426552",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426553",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426554",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426555",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426556",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426557",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426558",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426559",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426510",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426511",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426512",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426513",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426514",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426515",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426516",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426517",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426518",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426519",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426560",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426561",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426562",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426563",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426564",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426565",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426566",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426567",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426568",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426569",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426520",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426521",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426522",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426523",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426524",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426525",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426526",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426527",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426528",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426529",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426570",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426571",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426572",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426573",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426574",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426575",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426576",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426577",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426578",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426579",
      "Supply Voltage": "380-415 VAC",
      "Breaking Capacity": "70 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426100",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426101",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426102",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426103",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426104",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426105",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426106",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426107",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426108",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426109",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426150",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426151",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426152",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426153",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426154",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426155",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426156",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426157",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426158",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426159",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426110",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426111",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426112",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426113",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426114",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426115",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426116",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426117",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426118",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426119",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426160",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426161",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426162",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426163",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426164",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426165",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426166",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426167",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426168",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426169",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426120",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426121",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426122",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426123",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426124",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426125",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426126",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426127",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426128",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426129",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426170",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426171",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426172",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426173",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426174",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426175",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426176",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426177",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426178",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426179",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426200",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426201",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426202",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426203",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426204",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426205",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426206",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426207",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426208",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426209",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426250",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426251",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426252",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426253",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426254",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426255",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426256",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426257",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426258",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426259",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426210",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426211",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426212",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426213",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426214",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426215",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426216",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426217",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426218",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426219",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426260",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426261",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426262",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426263",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426264",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426265",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426266",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426267",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426268",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426269",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426220",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426221",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426222",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426223",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426224",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426225",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426226",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426227",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426228",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426229",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426270",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426271",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426272",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426273",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426274",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426275",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426276",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426277",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426278",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426279",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426300",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426301",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426302",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426303",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426304",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426305",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426306",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426307",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426308",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426309",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426350",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426351",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426352",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426353",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426354",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426355",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426356",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426357",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426358",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426359",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426310",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426311",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426312",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426313",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426314",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426315",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426316",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426317",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426318",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426319",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426360",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426361",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426362",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426363",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426364",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426365",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426366",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426367",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426368",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426369",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426320",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426321",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426322",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426323",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426324",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426325",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426326",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426327",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426328",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426329",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426370",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426371",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426372",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426373",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426374",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426375",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426376",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426377",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426378",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426379",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "85 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426400",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426401",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426402",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426403",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426404",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426405",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426406",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426407",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426408",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426409",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426450",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426451",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426452",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426453",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426454",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426455",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426456",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426457",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426458",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426459",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426410",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426411",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426412",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426413",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426414",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426415",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426416",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426417",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426418",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426419",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426460",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426461",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426462",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426463",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426464",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426465",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426466",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426467",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426468",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426469",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426420",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426421",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426422",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426423",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426424",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426425",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426426",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426427",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426428",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426429",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426470",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426471",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426472",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426473",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426474",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426475",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426476",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426477",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426478",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426479",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "90 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426500",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426501",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426502",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426503",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426504",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426505",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426506",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426507",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426508",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426509",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426550",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426551",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426552",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426553",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426554",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426555",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426556",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426557",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426558",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426559",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426510",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426511",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426512",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426513",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426514",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426515",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426516",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426517",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426518",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426519",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426560",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426561",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426562",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426563",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426564",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426565",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426566",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426567",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426568",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426569",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426520",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426521",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426522",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426523",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426524",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426525",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426526",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426527",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426528",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426529",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426570",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426571",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426572",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426573",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426574",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426575",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426576",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426577",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426578",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426579",
      "Supply Voltage": "220-240 VAC",
      "Breaking Capacity": "100 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426100",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426101",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426102",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426103",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426104",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426105",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426106",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426107",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426108",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426109",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426150",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426151",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426152",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426153",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426154",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426155",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426156",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426157",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426158",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426159",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426110",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426111",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426112",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426113",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426114",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426115",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426116",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426117",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426118",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426119",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426160",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426161",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426162",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426163",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426164",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426165",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426166",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426167",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426168",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426169",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426120",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426121",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426122",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426123",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426124",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426125",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426126",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426127",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426128",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426129",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426170",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426171",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426172",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426173",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426174",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426175",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426176",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426177",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426178",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426179",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426200",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426201",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426202",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426203",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426204",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426205",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426206",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426207",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426208",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426209",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426250",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426251",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426252",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426253",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426254",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426255",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426256",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426257",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426258",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426259",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426210",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426211",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426212",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426213",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426214",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426215",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426216",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426217",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426218",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426219",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426260",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426261",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426262",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426263",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426264",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426265",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426266",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426267",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426268",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426269",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426220",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426221",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426222",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426223",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426224",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426225",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426226",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426227",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426228",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426229",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426270",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426271",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426272",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426273",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426274",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426275",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426276",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426277",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426278",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426279",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "20 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426300",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426301",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426302",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426303",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426304",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426305",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426306",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426307",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426308",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426309",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426350",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426351",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426352",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426353",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426354",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426355",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426356",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426357",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426358",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426359",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426310",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426311",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426312",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426313",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426314",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426315",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426316",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426317",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426318",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426319",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426360",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426361",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426362",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426363",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426364",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426365",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426366",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426367",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426368",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426369",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426320",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426321",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426322",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426323",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426324",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426325",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426326",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426327",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426328",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426329",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426370",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426371",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426372",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426373",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426374",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426375",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426376",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426377",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426378",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426379",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "35 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426400",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426401",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426402",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426403",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426404",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426405",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426406",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426407",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426408",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426409",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426450",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426451",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426452",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426453",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426454",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426455",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426456",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426457",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426458",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426459",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426410",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426411",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426412",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426413",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426414",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426415",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426416",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426417",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426418",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426419",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426460",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426461",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426462",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426463",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426464",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426465",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426466",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426467",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426468",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426469",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426420",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426421",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426422",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426423",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426424",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426425",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426426",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426427",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426428",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426429",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426470",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426471",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426472",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426473",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426474",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426475",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426476",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426477",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426478",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426479",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "50 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426500",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426501",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426502",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426503",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426504",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426505",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426506",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426507",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426508",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426509",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426550",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426551",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426552",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426553",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426554",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426555",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426556",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "80 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426557",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "100 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426558",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "125 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426559",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "160 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426510",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426511",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426512",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426513",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426514",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426515",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426516",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426517",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426518",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426519",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426560",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426561",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426562",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426563",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426564",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426565",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426566",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426567",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426568",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426569",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426520",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426521",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426522",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426523",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426524",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426525",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426526",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426527",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426528",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426529",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426570",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426571",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426572",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426573",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426574",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426575",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426576",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "80 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426577",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "100 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426578",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "125 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426579",
      "Supply Voltage": "440 VAC",
      "Breaking Capacity": "65 kA",
      "Current Rating": "160 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426100",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426101",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426102",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426103",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426104",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426105",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426150",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426151",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426152",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426153",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426154",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426155",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426110",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426111",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426112",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426113",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426114",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426115",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426160",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426161",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426162",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426163",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426164",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426165",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426120",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426121",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426122",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426123",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426124",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426125",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426170",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426171",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426172",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426173",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426174",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426175",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "8 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426200",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426201",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426202",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426203",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426204",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426205",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426250",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426251",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426252",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426253",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426254",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426255",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426210",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426211",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426212",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426213",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426214",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426215",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426260",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426261",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426262",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426263",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426264",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426265",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426220",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426221",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426222",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426223",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426224",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426225",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426270",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426271",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426272",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426273",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426274",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426275",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426300",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426301",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426302",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426303",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426304",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426305",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426350",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426351",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426352",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426353",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426354",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426355",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426310",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426311",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426312",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426313",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426314",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426315",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426360",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426361",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426362",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426363",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426364",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426365",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426320",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426321",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426322",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426323",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426324",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426325",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426370",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426371",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426372",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426373",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426374",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426375",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426400",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426401",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426402",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426403",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426404",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426405",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426450",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426451",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426452",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426453",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426454",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426455",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426410",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426411",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426412",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426413",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426414",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426415",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426460",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426461",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426462",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426463",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426464",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426465",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426420",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426421",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426422",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426423",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426424",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426425",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426470",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426471",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426472",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426473",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426474",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426475",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "25 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426500",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426501",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426502",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426503",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426504",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426505",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426550",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426551",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426552",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426553",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426554",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426555",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426510",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426511",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426512",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426513",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426514",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426515",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426560",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426561",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426562",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426563",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426564",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426565",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426520",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426521",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426522",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426523",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426524",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426525",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426570",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426571",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426572",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426573",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426574",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426575",
      "Supply Voltage": "500 VAC",
      "Breaking Capacity": "30 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426300",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426301",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426302",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426303",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426304",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426305",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426350",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426351",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426352",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426353",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426354",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426355",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426310",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426311",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426312",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426313",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426314",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426315",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426360",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426361",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426362",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426363",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426364",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426365",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426320",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426321",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426322",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426323",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426324",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426325",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426370",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426371",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426372",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426373",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426374",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426375",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426400",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426401",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426402",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426403",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426404",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426405",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426450",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426451",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426452",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426453",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426454",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426455",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426410",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426411",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426412",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426413",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426414",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426415",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426460",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426461",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426462",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426463",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426464",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426465",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426420",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426421",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426422",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426423",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426424",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426425",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426470",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426471",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426472",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426473",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426474",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426475",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "15 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426500",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426501",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426502",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426503",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426504",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426505",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426550",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426551",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426552",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426553",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426554",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426555",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426510",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426511",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426512",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426513",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426514",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426515",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426560",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426561",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426562",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426563",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426564",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426565",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426520",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426521",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426522",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426523",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426524",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426525",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426570",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426571",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426572",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426573",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426574",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426575",
      "Supply Voltage": "525 VAC",
      "Breaking Capacity": "22 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426400",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426401",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426402",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426403",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426404",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426405",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426450",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426451",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426452",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426453",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426454",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426455",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426410",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426411",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426412",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426413",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426414",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426415",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426460",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426461",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426462",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426463",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426464",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426465",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426420",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426421",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426422",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426423",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426424",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426425",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426470",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426471",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426472",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426473",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426474",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426475",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426500",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426501",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426502",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426503",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426504",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426505",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426550",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426551",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426552",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426553",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426554",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426555",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "3-Poles Frame",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426510",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426511",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426512",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426513",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426514",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426515",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426560",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426561",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426562",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426563",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426564",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426565",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 3-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426520",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426521",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426522",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426523",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426524",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426525",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "EverLink™ connectors"
    },
    {
      "MCCB Reference": "LV426570",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "16 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426571",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "25 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426572",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "32 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426573",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "40 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426574",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "50 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    },
    {
      "MCCB Reference": "LV426575",
      "Supply Voltage": "660-690 VAC",
      "Breaking Capacity": "10 kA",
      "Current Rating": "63 A",
      "No of Poles": "4-Poles Frame w 4-Poles Detection",
      "Connector Type": "Compression lug/busbar connectors"
    }
  ]
}

export default definition;
