const SkippedKeys = [
  'Sensor Reference',
  'I/O Module Reference',
  'PLC Reference',
  'Power Supply Reference',
  'DOL Reference',
  // DOL
  'Motor Power (hp)',
  'Coil Voltage',

  // doorAndGateSafetyControl
  "Safety Switch Reference",
  "Safety Module Refernce",
  "Switch Material",
  // push button
  'Push Button Reference',
  'Safety Module Refernce',
  // enclosure
  'Enclosure Reference',
  // hmi
  'Display Reference',
  'Box Reference',
  // miniature circuit breaker
  'MCB Reference',
  // molded case circuit breaker
  'MCCB Reference',
  // motion control solution
  'Servo Motor Reference',
  'Servo Drive Reference',
  'Breaker Reference',
  // motor control solution
  'Drive Reference',
  'Breaker Reference',
  'Contactor Reference',
  // Photoelectric Sensor
  'Reflector Reference',
  // push button
  'Push Button Reference',
  // temperature control
  'Temperature Control Reference',
  'SSR Reference',
  // uninteruptable power supply
  'UPS Reference'
];

function columnSorting(mapping) {
  return (a, b) => mapping[a] - mapping[b];
}

function cleanData(data) {
  let result = [];
  data.forEach(entry => {
    let finalEntries = [entry];
    const keys = Object.keys(entry);
    for (let j = 0, key; key = keys[j]; j++) {
      const values = entry[key]
        .split(/,|;/)
        .map(val => val.trim());

      if (values.length > 1) {
        const newFinalEntries = [];
        for (let k = 0, currentVal; currentVal = values[k]; k++) {
          finalEntries.forEach(fe => {
            newFinalEntries.push({ ...fe, [key]: currentVal })
          });
        }
        finalEntries = newFinalEntries;
      }
    }

    result = [...result, ...finalEntries];
  });

  return result;
}

export const graphBuilder = function (selected) {
  const data = cleanData(selected.data);

  let currentId = 0;
  const graphData = {
    nodes: [{
      id: 0, label: 'START',
    }],
    edges: [],
    height: '1000px',
  };
  data.forEach(element => {
    let prev = null;

    Object
      .keys(element)
      .sort(columnSorting(selected.columnOrder))
      .forEach((key, index) => {
        if (SkippedKeys.indexOf(key) >= 0) {

        } else {
          if (element[key] === 'PNP') {
            debugger;
          }
          let existingNode = graphData.nodes.find(x => x.label === element[key]);
          currentId++;
          if (!existingNode) {
            existingNode = {
              id: currentId,
              label: element[key]
            };
            graphData.nodes.push(existingNode);
          }

          let currentNodeId = existingNode?.id || currentId;

          const prevIndex = prev?.id || 0;

          const edgeIndex = graphData.edges.find(e => e.from === prevIndex && e.to === currentNodeId)
          if (!edgeIndex) {
            graphData.edges.push({
              from: prevIndex,
              to: currentNodeId
            });
          }

          prev = graphData.nodes.find(x => x.label === element[key]);
        }
      });
  });
  console.log(graphData);

  graphData.edges.sort((a, b) => (a.from - b.from));
  return graphData;
};
