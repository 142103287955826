const definition = {
  columnOrder: {
    "Push Button Reference": 1,
    "Type": 2,
    "Bazel Material": 3,
    "Mounting Type": 4,
    "Operator Profile": 5,
    "Color": 6,
    "Marking": 7,
    "Reset Method": 8,
    "Switch Type": 9,
    "Supply Voltage": 10
  },
  data: [
    {
      "Push Button Reference": "XB4BA11",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BA21",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Black",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BA31",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BA3311",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "I",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BA3341",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "?",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BA3351",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Black",
      "Marking": "?",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BA42",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BA4322",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "O",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BA51",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Yellow",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BA61",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BC21",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Mushroom Ø 40 mm",
      "Color": "Black",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BD21",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BD33",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BD41",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BD53",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BG03",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BG41",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BG61",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BJ21",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BJ33",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BJ53",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BK123B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BK123G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BK123M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BK124B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BK124G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BK124M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BK125B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BK125G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BK125M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BK133B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BK133G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BK133M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BK134B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BK134G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BK134M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BK135B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BK135G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BK135M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BL42",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Projecting",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BL4325",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Projecting",
      "Color": "Red",
      "Marking": "O",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BP21",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Booted, Clear Silicon",
      "Color": "Black",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BP31",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Booted, Clear Silicon",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BP42",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Booted, Clear Silicon",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BP51",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Booted, Clear Silicon",
      "Color": "Yellow",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BP61",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Booted, Clear Silicon",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4BVB1",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BVB3",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BVB4",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BVB5",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BVB6",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BVG1",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BVG3",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BVG4",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BVG5",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BVG6",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BVM1",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BVM3",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BVM4",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BVM5",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BVM6",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BW31B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BW31G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BW31M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BW33B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BW33G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BW33M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BW34B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BW34G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BW34M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BW35B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BW35G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BW35M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4BW36B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4BW36G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4BW36M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FA11",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FA21",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Black",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FA31",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FA3311",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "I",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FA3341",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "?",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FA3351",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Black",
      "Marking": "?",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FA42",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FA4322",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "O",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FA51",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Yellow",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FA61",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FD21",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FD33",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FD41",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FD53",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FG03",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FG41",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FG61",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FJ21",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FJ33",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FJ53",
      "Type": "Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FK123B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FK123G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FK123M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FK124B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FK124G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FK124M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FK125B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FK125G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FK125M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FK133B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FK133G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FK133M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FK134B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FK134G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FK134M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FK135B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FK135G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FK135M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FL42",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Projecting",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FL4325",
      "Type": "Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Projecting",
      "Color": "Red",
      "Marking": "O",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB4FVB1",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FVB3",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FVB4",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FVB5",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FVB6",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FVG1",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FVG3",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FVG4",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FVG5",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FVG6",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FVM1",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FVM3",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FVM4",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FVM5",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FVM6",
      "Type": "Pilot Light",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FW31B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FW31G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FW31M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FW33B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FW33G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FW33M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FW34B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FW34G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FW34M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FW35B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FW35G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FW35M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB4FW36B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB4FW36G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB4FW36M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Metal",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AA11",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AA21",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Black",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AA31",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AA3311",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "I",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AA3341",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "?",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AA3351",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Black",
      "Marking": "?",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AA42",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AA4322",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "O",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AA51",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Yellow",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AA61",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AC21",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Mushroom Ø 40 mm",
      "Color": "Black",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AD21",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AD33",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AD41",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AD53",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AG03",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AG41",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AG61",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AJ21",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AJ33",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AJ53",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AK123B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AK123G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AK123M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AK124B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AK124G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AK124M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AK125B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AK125G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AK125M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AK133B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AK133G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AK133M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AK134B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AK134G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AK134M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AK135B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AK135G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AK135M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AL42",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Projecting",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AL4322",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Projecting",
      "Color": "Red",
      "Marking": "O",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AP21",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Booted, Clear Silicon",
      "Color": "Black",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AP31",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Booted, Clear Silicon",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AP42",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Booted, Clear Silicon",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AP51",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Booted, Clear Silicon",
      "Color": "Yellow",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AP61",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Booted, Clear Silicon",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5AVB1",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AVB3",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AVB4",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AVB5",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AVB6",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AVG1",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AVG3",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AVG4",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AVG5",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AVG6",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AVM1",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AVM3",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AVM4",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AVM5",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AVM6",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AW31B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AW31G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AW31M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AW33B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AW33G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AW33M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AW34B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AW34G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AW34M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AW35B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AW35G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AW35M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5AW36B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5AW36G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5AW36M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 22 mm Standard",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FA11",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FA21",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Black",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FA31",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FA3311",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "I",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FA3341",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "?",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FA3351",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Black",
      "Marking": "?",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FA42",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FA4322",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "O",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FA51",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Yellow",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FA61",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FD21",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FD33",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FD41",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FD53",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FG03",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FG41",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FG61",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Key Switch",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FJ21",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FJ33",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FJ53",
      "Type": "Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Long Handle",
      "Color": "Black",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Spring Return",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FK123B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FK123G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FK123M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FK124B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FK124G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FK124M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FK125B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FK125G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FK125M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "2 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FK133B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FK133G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FK133M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FK134B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FK134G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FK134M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FK135B5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FK135G5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FK135M5",
      "Type": "Illuminated Selector Switch",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Standard Handle",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "3 Position Stay Put",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FL42",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Projecting",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FL4322",
      "Type": "Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Projecting",
      "Color": "Red",
      "Marking": "O",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "n/a"
    },
    {
      "Push Button Reference": "XB5FVB1",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FVB3",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FVB4",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FVB5",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FVB6",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FVG1",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FVG3",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FVG4",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FVG5",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FVG6",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FVM1",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "White",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FVM3",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Green",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FVM4",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Red",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FVM5",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Orange",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FVM6",
      "Type": "Pilot Light",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "n/a",
      "Color": "Blue",
      "Marking": "n/a",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FW31B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FW31G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FW31M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "White",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FW33B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FW33G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FW33M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Green",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FW34B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FW34G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FW34M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Red",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FW35B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FW35G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FW35M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Orange",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    },
    {
      "Push Button Reference": "XB5FW36B5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "24 VDC/VAC"
    },
    {
      "Push Button Reference": "XB5FW36G5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "110-120 VAC"
    },
    {
      "Push Button Reference": "XB5FW36M5",
      "Type": "Illuminated Pushbutton",
      "Bazel Material": "Plastic",
      "Mounting Type": "Ø 30 mm Build-in Flush",
      "Operator Profile": "Flush",
      "Color": "Blue",
      "Marking": "No",
      "Reset Method": "n/a",
      "Switch Type": "n/a",
      "Supply Voltage": "230-240 VAC"
    }
  ]
}

export default definition;
