const definition = {
  columnOrder: {
    "Sensor Reference": 1,
    "Reflector Reference": 2,
    "Object Material": 3,
    "Detection System": 4,
    "Sensor Shape & Material": 5,
    "Sensing Distance": 6,
    "Line of Sight": 7,
    "Output Function": 8,
    "Connection": 9
  },
  data: [
    {
      "Sensor Reference": "XUBTAPSNL2",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTANSNL2",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTAPSWL2",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTANSWL2",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTAPSNL5",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTANSNL5",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTAPSWL5",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTANSWL5",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTAPSNM12",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUBTANSNM12",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUBTAPSWM12",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUBTANSWM12",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUBTSPSNL2",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTSNSNL2",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTSPSWL2",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTSNSWL2",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTSPSNL5",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTSNSNL5",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTSPSWL5",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTSNSWL5",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBTSPSNM12",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUBTSNSNM12",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "1.4 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUBTSPSWM12",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUBTSNSWM12",
      "Reflector Reference": "",
      "Object Material": "Transparent",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Stainless Steel",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUBT1PSNL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBT1NSNL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBT1PSNL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBT1NSNL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUBT1PSNM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUBT1NSNM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.8 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO/NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUMTAPCNL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUMTANCNL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUMTAPCNM8",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "M8 Connector"
    },
    {
      "Sensor Reference": "XUMTANCNM8",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, NPN",
      "Connection": "M8 Connector"
    },
    {
      "Sensor Reference": "XUMTAPCNL03M12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUMTAPCNL2",
      "Reflector Reference": "XUZC50CR",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "1 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUMTANCNL2",
      "Reflector Reference": "XUZC50CR",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "1 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUMTAPCNM8",
      "Reflector Reference": "XUZC50CR",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "1 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "M8 Connector"
    },
    {
      "Sensor Reference": "XUMTANCNM8",
      "Reflector Reference": "XUZC50CR",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "1 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, NPN",
      "Connection": "M8 Connector"
    },
    {
      "Sensor Reference": "XUMTAPCNL03M12",
      "Reflector Reference": "XUZC50CR",
      "Object Material": "Transparent",
      "Detection System": "Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "1 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5APANM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5APAWM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5ANANM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5ANAWM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5APBNM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5APBWM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5ANBNM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5ANBWM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9APANM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9APAWM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9ANANM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9ANAWM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9APBNM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9APBWM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9ANBNM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9ANBWM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5APANL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5APAWL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5ANANL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5ANAWL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5APBNL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5APBWL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5ANBNL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5ANBWL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9APANL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9APAWL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9ANANL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9ANAWL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9APBNL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9APBWL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9ANBNL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9ANBWL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5APANL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5APAWL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5ANANL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5ANAWL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5APBNL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5APBWL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5ANBNL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5ANBWL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9APANL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9APAWL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9ANANL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9ANAWL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9APBNL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9APBWL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9ANBNL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9ANBWL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUM5APCNL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "1 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUM5APCNM8",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "1 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "M8 Connector"
    },
    {
      "Sensor Reference": "XUM5ANCNL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "1 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUM5ANCNM8",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "1 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, NPN",
      "Connection": "M8 Connector"
    },
    {
      "Sensor Reference": "XUM9APCNL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "5 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUM9APCNM8",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "5 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "M8 Connector"
    },
    {
      "Sensor Reference": "XUM9ANCNL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "5 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUM9ANCNM8",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "5 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, NPN",
      "Connection": "M8 Connector"
    },
    {
      "Sensor Reference": "XUM9APCNL2",
      "Reflector Reference": "XUZC80",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUM9APCNM8",
      "Reflector Reference": "XUZC80",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, PNP",
      "Connection": "M8 Connector"
    },
    {
      "Sensor Reference": "XUM9ANCNL2",
      "Reflector Reference": "XUZC80",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUM9ANCNM8",
      "Reflector Reference": "XUZC80",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Miniature Plastic",
      "Sensing Distance": "2 M",
      "Line of Sight": "n/a",
      "Output Function": "NO/NC, NPN",
      "Connection": "M8 Connector"
    },
    {
      "Sensor Reference": "XUB5BPANM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5BPAWM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5BNANM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5BNAWM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5BPBNM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5BPBWM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5BNBNM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5BNBWM12",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9BPANM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9BPAWM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9BNANM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9BNAWM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9BPBNM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9BPBWM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9BNBNM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB9BNBWM12",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "M12 Connector"
    },
    {
      "Sensor Reference": "XUB5BPANL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BPAWL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BNANL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BNAWL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BPBNL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BPBWL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BNBNL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BNBWL2",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BPANL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BPAWL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BNANL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BNAWL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BPBNL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BPBWL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BNBNL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BNBWL2",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "2 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BPANL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BPAWL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BNANL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BNAWL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BPBNL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BPBWL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BNBNL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB5BNBWL5",
      "Reflector Reference": "",
      "Object Material": "Standard",
      "Detection System": "Diffuse with adjustable sensitivity",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "0.6 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BPANL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BPAWL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BNANL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BNAWL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NO, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BPBNL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BPBWL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, PNP",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BNBNL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "Along case axis",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled"
    },
    {
      "Sensor Reference": "XUB9BNBWL5",
      "Reflector Reference": "XUZC50",
      "Object Material": "Standard",
      "Detection System": "Polarised Reflex",
      "Sensor Shape & Material": "Cylindrical Metal",
      "Sensing Distance": "2 M",
      "Line of Sight": "90° to case axis",
      "Output Function": "NC, NPN",
      "Connection": "5 M Pre-cabled"
    }
  ]
}

export default definition;
