const definition = {
  columnOrder: {
    "PLC Reference": 1,
    "Power Supply Reference": 2,
    "Funtionality": 3,
    "Number of I/O": 4,
    "Output Type": 5,
    "Ethernet Port": 6,
    "CANOpen": 7,
    "Connection Type": 8,
    "Format": 9,
    "Axis": 10,
    "Power Supply Output Current": 11
  },
  data: [
    {
      "PLC Reference": "TM221C16T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221C16U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221C24T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221C24U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221C40T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221C40U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221CE16T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221CE16U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221CE24T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221CE24U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221CE40T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221CE40U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221M16R",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221M16RG",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221M16T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221M16TG",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221M32TK",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221ME16R",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221ME16RG",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221ME16T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221ME16TG",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221ME32TK",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM241C24T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM241C24U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM241C40T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM241C40U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM241CE24T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM241CE24U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM241CE40T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM241CE40U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM241CEC24T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM241CEC24U",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM262M15MESS8T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "4 Axis",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM262M25MESS8T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "8 Axis",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM262M35MESS8T",
      "Power Supply Reference": "ABLS1A24021",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "16 Axis",
      "Power Supply Output Current": "2.1 A"
    },
    {
      "PLC Reference": "TM221C16T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221C16U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221C24T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221C24U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221C40T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221C40U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221CE16T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221CE16U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221CE24T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221CE24U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221CE40T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221CE40U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221M16R",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221M16RG",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221M16T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221M16TG",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221M32TK",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221ME16R",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221ME16RG",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221ME16T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221ME16TG",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221ME32TK",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM241C24T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM241C24U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM241C40T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM241C40U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM241CE24T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM241CE24U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM241CE40T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM241CE40U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM241CEC24T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM241CEC24U",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM262M15MESS8T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "4 Axis",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM262M25MESS8T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "8 Axis",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM262M35MESS8T",
      "Power Supply Reference": "ABLS1A24031",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "16 Axis",
      "Power Supply Output Current": "3.13 A"
    },
    {
      "PLC Reference": "TM221C16T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221C16U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221C24T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221C24U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221C40T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221C40U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221CE16T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221CE16U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221CE24T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221CE24U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221CE40T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221CE40U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221M16R",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221M16RG",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221M16T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221M16TG",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221M32TK",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221ME16R",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221ME16RG",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221ME16T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221ME16TG",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221ME32TK",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM241C24T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM241C24U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM241C40T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM241C40U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM241CE24T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM241CE24U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM241CE40T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM241CE40U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM241CEC24T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM241CEC24U",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM262M15MESS8T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "4 Axis",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM262M25MESS8T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "8 Axis",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM262M35MESS8T",
      "Power Supply Reference": "ABLS1A24038",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "16 Axis",
      "Power Supply Output Current": "3.8 A"
    },
    {
      "PLC Reference": "TM221C16T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221C16U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221C24T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221C24U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221C40T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221C40U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221CE16T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221CE16U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221CE24T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221CE24U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221CE40T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221CE40U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221M16R",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221M16RG",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221M16T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221M16TG",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221M32TK",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221ME16R",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221ME16RG",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221ME16T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221ME16TG",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221ME32TK",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM241C24T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM241C24U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM241C40T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM241C40U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM241CE24T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM241CE24U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM241CE40T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM241CE40U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM241CEC24T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM241CEC24U",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM262M15MESS8T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "4 Axis",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM262M25MESS8T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "8 Axis",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM262M35MESS8T",
      "Power Supply Reference": "ABLS1A24050",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "16 Axis",
      "Power Supply Output Current": "5 A"
    },
    {
      "PLC Reference": "TM221C16T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221C16U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221C24T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221C24U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221C40T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221C40U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221CE16T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221CE16U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221CE24T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221CE24U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221CE40T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221CE40U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221M16R",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221M16RG",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221M16T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221M16TG",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221M32TK",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221ME16R",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221ME16RG",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221ME16T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221ME16TG",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221ME32TK",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM241C24T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM241C24U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM241C40T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM241C40U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM241CE24T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM241CE24U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM241CE40T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM241CE40U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM241CEC24T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM241CEC24U",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM262M15MESS8T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "4 Axis",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM262M25MESS8T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "8 Axis",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM262M35MESS8T",
      "Power Supply Reference": "ABLS1A24100",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "16 Axis",
      "Power Supply Output Current": "10 A"
    },
    {
      "PLC Reference": "TM221C16T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221C16U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221C24T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221C24U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221C40T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221C40U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221CE16T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221CE16U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221CE24T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221CE24U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221CE40T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221CE40U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221M16R",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221M16RG",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221M16T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221M16TG",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221M32TK",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221ME16R",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221ME16RG",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221ME16T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221ME16TG",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Spring Terminal",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM221ME32TK",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Basic Application",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "HE 10 Connector",
      "Format": "Book",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM241C24T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM241C24U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM241C40T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM241C40U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "No",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM241CE24T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM241CE24U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM241CE40T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM241CE40U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Advance Application",
      "Number of I/O": "40 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "No",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM241CEC24T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM241CEC24U",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Advance Application",
      "Number of I/O": "24 I/O",
      "Output Type": "Sink Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Standard",
      "Axis": "n/a",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM262M15MESS8T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "4 Axis",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM262M25MESS8T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "8 Axis",
      "Power Supply Output Current": "20 A"
    },
    {
      "PLC Reference": "TM262M35MESS8T",
      "Power Supply Reference": "ABLS1A24200",
      "Funtionality": "Synchronized Motion or Embedded Safety",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Ethernet Port": "Yes",
      "CANOpen": "Yes",
      "Connection Type": "Screw Terminal",
      "Format": "Book",
      "Axis": "16 Axis",
      "Power Supply Output Current": "20 A"
    }
  ]
};

export default definition;
