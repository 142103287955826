// import logo from './logo.svg';
import './App.scss';

import Graph from "react-graph-vis";
import { uuid } from 'uuidv4';

import * as mappingData from './data';

import { graphBuilder } from './helpers';
import { useState } from 'react';

import Select from 'react-select';

function App() {
  const [selectedMapping, setSelectedMapping] = useState(null);


  const options = {
    interaction: {
      multiselect: true,
    },
    layout: {
      hierarchical: {
        enabled: true,
        direction: 'LR',
        levelSeparation: 300,
        sortMethod: 'directed',
      }
    },
    edges: {
      color: "#000000"
    },
  };

  const selectOptions = Object.keys(mappingData).map(key => ({
    label: key,
    value: key
  }));

  debugger;
  return (
    <div className="App">
      <div>
        <Select
          options={selectOptions}
          onChange={val => setSelectedMapping(val.value)}
        />
      </div>
      {
        selectedMapping &&
        (
          <Graph
            key={uuid()}
            graph={graphBuilder(mappingData[selectedMapping])}
            options={options}
          />
        )
      }
    </div>
  );
}

export default App;
