const data = {
  columnOrder: {
    "I/O Module Reference": 100,
    "Type": 1,
    "Number of I/O": 2,
    "Output Type": 3,
    "Connection Type": 4
  },
  data: [
    {
      "I/O Module Reference": "TM3XTRA1",
      "Type": "Remote Transmitter Module",
      "Number of I/O": "n/a",
      "Output Type": "n/a",
      "Connection Type": "n/a"
    },
    {
      "I/O Module Reference": "TM3DM8R",
      "Type": "I/O Expansion Module",
      "Number of I/O": "8 I/O",
      "Output Type": "Relay Output",
      "Connection Type": "Screw Terminal"
    },
    {
      "I/O Module Reference": "TM3DM8RG",
      "Type": "I/O Expansion Module",
      "Number of I/O": "8 I/O",
      "Output Type": "Relay Output",
      "Connection Type": "Spring Terminal"
    },
    {
      "I/O Module Reference": "TM3DM24R",
      "Type": "I/O Expansion Module",
      "Number of I/O": "24 I/O",
      "Output Type": "Relay Output",
      "Connection Type": "Screw Terminal"
    },
    {
      "I/O Module Reference": "TM3DM24RG",
      "Type": "I/O Expansion Module",
      "Number of I/O": "24 I/O",
      "Output Type": "Relay Output",
      "Connection Type": "Spring Terminal"
    },
    {
      "I/O Module Reference": "TM3DI8",
      "Type": "Input Module",
      "Number of I/O": "8 I/O",
      "Output Type": "n/a",
      "Connection Type": "Screw Terminal"
    },
    {
      "I/O Module Reference": "TM3DI8G",
      "Type": "Input Module",
      "Number of I/O": "8 I/O",
      "Output Type": "n/a",
      "Connection Type": "Spring Terminal"
    },
    {
      "I/O Module Reference": "TM3DI16",
      "Type": "Input Module",
      "Number of I/O": "16 I/O",
      "Output Type": "n/a",
      "Connection Type": "Screw Terminal"
    },
    {
      "I/O Module Reference": "TM3DI16G",
      "Type": "Input Module",
      "Number of I/O": "16 I/O",
      "Output Type": "n/a",
      "Connection Type": "Spring Terminal"
    },
    {
      "I/O Module Reference": "TM3DI16K",
      "Type": "Input Module",
      "Number of I/O": "16 I/O",
      "Output Type": "n/a",
      "Connection Type": "HE 10 Connector"
    },
    {
      "I/O Module Reference": "TM3DI32K",
      "Type": "Input Module",
      "Number of I/O": "32 I/O",
      "Output Type": "n/a",
      "Connection Type": "HE 10 Connector"
    },
    {
      "I/O Module Reference": "TM3DQ8R",
      "Type": "Output Module",
      "Number of I/O": "8 I/O",
      "Output Type": "Relay Output",
      "Connection Type": "Screw Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ8RG",
      "Type": "Output Module",
      "Number of I/O": "8 I/O",
      "Output Type": "Relay Output",
      "Connection Type": "Spring Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ8T",
      "Type": "Output Module",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Connection Type": "Screw Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ8TG",
      "Type": "Output Module",
      "Number of I/O": "8 I/O",
      "Output Type": "Source Output",
      "Connection Type": "Spring Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ8U",
      "Type": "Output Module",
      "Number of I/O": "8 I/O",
      "Output Type": "Sink Output",
      "Connection Type": "Screw Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ8UG",
      "Type": "Output Module",
      "Number of I/O": "8 I/O",
      "Output Type": "Sink Output",
      "Connection Type": "Spring Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ16R",
      "Type": "Output Module",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Connection Type": "Screw Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ16RG",
      "Type": "Output Module",
      "Number of I/O": "16 I/O",
      "Output Type": "Relay Output",
      "Connection Type": "Spring Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ16T",
      "Type": "Output Module",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Connection Type": "Screw Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ16TG",
      "Type": "Output Module",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Connection Type": "Spring Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ16TK",
      "Type": "Output Module",
      "Number of I/O": "16 I/O",
      "Output Type": "Source Output",
      "Connection Type": "HE 10 Connector"
    },
    {
      "I/O Module Reference": "TM3DQ16U",
      "Type": "Output Module",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Connection Type": "Screw Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ16UG",
      "Type": "Output Module",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Connection Type": "Spring Terminal"
    },
    {
      "I/O Module Reference": "TM3DQ16UK",
      "Type": "Output Module",
      "Number of I/O": "16 I/O",
      "Output Type": "Sink Output",
      "Connection Type": "HE 10 Connector"
    },
    {
      "I/O Module Reference": "TM3DQ32TK",
      "Type": "Output Module",
      "Number of I/O": "32 I/O",
      "Output Type": "Source Output",
      "Connection Type": "HE 10 Connector"
    },
    {
      "I/O Module Reference": "TM3DQ32UK",
      "Type": "Output Module",
      "Number of I/O": "32 I/O",
      "Output Type": "Sink Output",
      "Connection Type": "HE 10 Connector"
    }
  ]
};

export default data;