const definition = {
  columnOrder: {
    "Push Button Reference": 1,
    "Safety Module Refernce": 2,
    "Bazel Material": 3,
    "Reset Method": 4,
    "Safety Module Connection Type": 5
  },
  data: [
    {
      "Push Button Reference": "XB4BS8445",
      "Safety Module Refernce": "XPSAC5121",
      "Bazel Material": "Metal",
      "Reset Method": "Turn to Release",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Push Button Reference": "XB4BS9445",
      "Safety Module Refernce": "XPSAC5121",
      "Bazel Material": "Metal",
      "Reset Method": "Key Release",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Push Button Reference": "XB4BT845",
      "Safety Module Refernce": "XPSAC5121",
      "Bazel Material": "Metal",
      "Reset Method": "Push Pull",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Push Button Reference": "XB5AS8445",
      "Safety Module Refernce": "XPSAC5121",
      "Bazel Material": "Plastic",
      "Reset Method": "Turn to Release",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Push Button Reference": "XB5AS9445",
      "Safety Module Refernce": "XPSAC5121",
      "Bazel Material": "Plastic",
      "Reset Method": "Key Release",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Push Button Reference": "XB5AT845",
      "Safety Module Refernce": "XPSAC5121",
      "Bazel Material": "Plastic",
      "Reset Method": "Push Pull",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Push Button Reference": "XB4BS8445",
      "Safety Module Refernce": "XPSAC5121P",
      "Bazel Material": "Metal",
      "Reset Method": "Turn to Release",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Push Button Reference": "XB4BS9445",
      "Safety Module Refernce": "XPSAC5121P",
      "Bazel Material": "Metal",
      "Reset Method": "Key Release",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Push Button Reference": "XB4BT845",
      "Safety Module Refernce": "XPSAC5121P",
      "Bazel Material": "Metal",
      "Reset Method": "Push Pull",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Push Button Reference": "XB5AS8445",
      "Safety Module Refernce": "XPSAC5121P",
      "Bazel Material": "Plastic",
      "Reset Method": "Turn to Release",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Push Button Reference": "XB5AS9445",
      "Safety Module Refernce": "XPSAC5121P",
      "Bazel Material": "Plastic",
      "Reset Method": "Key Release",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Push Button Reference": "XB5AT845",
      "Safety Module Refernce": "XPSAC5121P",
      "Bazel Material": "Plastic",
      "Reset Method": "Push Pull",
      "Safety Module Connection Type": "Removable Terminal Block"
    }
  ]
};

export default definition;
