const definition = {
  columnOrder: {
    "Display Reference": 1,
    "Box Reference": 2,
    "Screen Type": 3,
    "Screen Size": 4,
    "Memory Size": 5,
    "Development Software": 6
  },
  data: [
    {
      "Display Reference": "HMIST6400",
      "Box Reference": "",
      "Screen Type": "Single Touch",
      "Screen Size": "7 inch",
      "Memory Size": "1 GB",
      "Development Software": "EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIST6500",
      "Box Reference": "",
      "Screen Type": "Single Touch",
      "Screen Size": "10.1 inch",
      "Memory Size": "1 GB",
      "Development Software": "EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIST6600",
      "Box Reference": "",
      "Screen Type": "Single Touch",
      "Screen Size": "12.1 inch",
      "Memory Size": "1 GB",
      "Development Software": "EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIST6700",
      "Box Reference": "",
      "Screen Type": "Single Touch",
      "Screen Size": "15.6 inch",
      "Memory Size": "1 GB",
      "Development Software": "EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIST6200",
      "Box Reference": "",
      "Screen Type": "Single Touch",
      "Screen Size": "4.3 inch",
      "Memory Size": "512 MB",
      "Development Software": "EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMISTW6200",
      "Box Reference": "",
      "Screen Type": "Single Touch",
      "Screen Size": "4.3 inch",
      "Memory Size": "512 MB",
      "Development Software": "Linux Browser HTML5"
    },
    {
      "Display Reference": "HMISTW6400",
      "Box Reference": "",
      "Screen Type": "Single Touch",
      "Screen Size": "7 inch",
      "Memory Size": "512 MB",
      "Development Software": "Linux Browser HTML5"
    },
    {
      "Display Reference": "HMISTW6500",
      "Box Reference": "",
      "Screen Type": "Single Touch",
      "Screen Size": "10.1 inch",
      "Memory Size": "512 MB",
      "Development Software": "Linux Browser HTML5"
    },
    {
      "Display Reference": "HMISTW6600",
      "Box Reference": "",
      "Screen Type": "Single Touch",
      "Screen Size": "12.1 inch",
      "Memory Size": "512 MB",
      "Development Software": "Linux Browser HTML5"
    },
    {
      "Display Reference": "HMISTW6700",
      "Box Reference": "",
      "Screen Type": "Single Touch",
      "Screen Size": "15.6 inch",
      "Memory Size": "512 MB",
      "Development Software": "Linux Browser HTML5"
    },
    {
      "Display Reference": "HMIDT351",
      "Box Reference": "HMIG2U",
      "Screen Type": "Single Touch",
      "Screen Size": "7 inch",
      "Memory Size": "1 GB",
      "Development Software": "Vijeo Designer"
    },
    {
      "Display Reference": "HMIDT551",
      "Box Reference": "HMIG2U",
      "Screen Type": "Single Touch",
      "Screen Size": "10.1 inch",
      "Memory Size": "1 GB",
      "Development Software": "Vijeo Designer"
    },
    {
      "Display Reference": "HMIDT651",
      "Box Reference": "HMIG2U",
      "Screen Type": "Single Touch",
      "Screen Size": "12.1 inch",
      "Memory Size": "1 GB",
      "Development Software": "Vijeo Designer"
    },
    {
      "Display Reference": "HMIDT542",
      "Box Reference": "HMIG2U",
      "Screen Type": "Multi Touch",
      "Screen Size": "10.4 inch",
      "Memory Size": "1 GB",
      "Development Software": "Vijeo Designer"
    },
    {
      "Display Reference": "HMIDT642",
      "Box Reference": "HMIG2U",
      "Screen Type": "Multi Touch",
      "Screen Size": "12.1 inch",
      "Memory Size": "1 GB",
      "Development Software": "Vijeo Designer"
    },
    {
      "Display Reference": "HMIDT732",
      "Box Reference": "HMIG2U",
      "Screen Type": "Multi Touch",
      "Screen Size": "15 inch",
      "Memory Size": "1 GB",
      "Development Software": "Vijeo Designer"
    },
    {
      "Display Reference": "HMIDT351",
      "Box Reference": "HMIG3U",
      "Screen Type": "Single Touch",
      "Screen Size": "7 inch",
      "Memory Size": "1 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT551",
      "Box Reference": "HMIG3U",
      "Screen Type": "Single Touch",
      "Screen Size": "10.1 inch",
      "Memory Size": "1 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT651",
      "Box Reference": "HMIG3U",
      "Screen Type": "Single Touch",
      "Screen Size": "12.1 inch",
      "Memory Size": "1 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT542",
      "Box Reference": "HMIG3U",
      "Screen Type": "Multi Touch",
      "Screen Size": "10.4 inch",
      "Memory Size": "1 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT642",
      "Box Reference": "HMIG3U",
      "Screen Type": "Multi Touch",
      "Screen Size": "12.1 inch",
      "Memory Size": "1 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT732",
      "Box Reference": "HMIG3U",
      "Screen Type": "Multi Touch",
      "Screen Size": "15 inch",
      "Memory Size": "1 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT752",
      "Box Reference": "HMIG3U",
      "Screen Type": "Multi Touch",
      "Screen Size": "15 inch",
      "Memory Size": "1 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT952",
      "Box Reference": "HMIG3U",
      "Screen Type": "Multi Touch",
      "Screen Size": "19 inch",
      "Memory Size": "1 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT351",
      "Box Reference": "HMIG5U2",
      "Screen Type": "Single Touch",
      "Screen Size": "7 inch",
      "Memory Size": "32 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT551",
      "Box Reference": "HMIG5U2",
      "Screen Type": "Single Touch",
      "Screen Size": "10.1 inch",
      "Memory Size": "32 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT651",
      "Box Reference": "HMIG5U2",
      "Screen Type": "Single Touch",
      "Screen Size": "12.1 inch",
      "Memory Size": "32 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT542",
      "Box Reference": "HMIG5U2",
      "Screen Type": "Multi Touch",
      "Screen Size": "10.4 inch",
      "Memory Size": "32 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT642",
      "Box Reference": "HMIG5U2",
      "Screen Type": "Multi Touch",
      "Screen Size": "12.1 inch",
      "Memory Size": "32 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT732",
      "Box Reference": "HMIG5U2",
      "Screen Type": "Multi Touch",
      "Screen Size": "15 inch",
      "Memory Size": "32 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    },
    {
      "Display Reference": "HMIDT952",
      "Box Reference": "HMIG5U2",
      "Screen Type": "Multi Touch",
      "Screen Size": "19 inch",
      "Memory Size": "32 GB (Expandable)",
      "Development Software": "Vijeo Designer; EcoStruxure Operator Terminal Expert"
    }
  ]
}

export default definition;
