const definition = {
  columnOrder: {
    "MCB Reference": 1,
    "Certificate": 2,
    "Current Rating": 3,
    "No of Poles": 4,
    "Breaking Curve": 5,
    "Connection Type": 6
  },
  data: [
    {
      "MCB Reference": "M9F44170",
      "Certificate": "UL/IEC",
      "Current Rating": "0.5 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44101",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44102",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44103",
      "Certificate": "UL/IEC",
      "Current Rating": "3 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44104",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44105",
      "Certificate": "UL/IEC",
      "Current Rating": "5 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44106",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44108",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44110",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44115",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44120",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44125",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44130",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44135",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44140",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44145",
      "Certificate": "UL/IEC",
      "Current Rating": "45 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44150",
      "Certificate": "UL/IEC",
      "Current Rating": "50 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F44163",
      "Certificate": "UL/IEC",
      "Current Rating": "63 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42170",
      "Certificate": "UL/IEC",
      "Current Rating": "0.5 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42101",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42102",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42103",
      "Certificate": "UL/IEC",
      "Current Rating": "3 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42104",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42105",
      "Certificate": "UL/IEC",
      "Current Rating": "5 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42106",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42107",
      "Certificate": "UL/IEC",
      "Current Rating": "7 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42108",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42110",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42113",
      "Certificate": "UL/IEC",
      "Current Rating": "13 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42115",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42120",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42125",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42130",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42135",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42140",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42145",
      "Certificate": "UL/IEC",
      "Current Rating": "45 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42150",
      "Certificate": "UL/IEC",
      "Current Rating": "50 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42163",
      "Certificate": "UL/IEC",
      "Current Rating": "63 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43170",
      "Certificate": "UL/IEC",
      "Current Rating": "0.5 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43101",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43102",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43103",
      "Certificate": "UL/IEC",
      "Current Rating": "3 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43104",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43105",
      "Certificate": "UL/IEC",
      "Current Rating": "5 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43106",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43108",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43110",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43115",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43120",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43125",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43130",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43135",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43140",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42201",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42202",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42203",
      "Certificate": "UL/IEC",
      "Current Rating": "3 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42204",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42205",
      "Certificate": "UL/IEC",
      "Current Rating": "5 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42206",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42207",
      "Certificate": "UL/IEC",
      "Current Rating": "7 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42208",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42210",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42213",
      "Certificate": "UL/IEC",
      "Current Rating": "13 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42215",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42220",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42225",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42230",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42235",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42240",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42245",
      "Certificate": "UL/IEC",
      "Current Rating": "45 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42250",
      "Certificate": "UL/IEC",
      "Current Rating": "50 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42263",
      "Certificate": "UL/IEC",
      "Current Rating": "63 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43201",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43202",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43203",
      "Certificate": "UL/IEC",
      "Current Rating": "3 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43204",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43205",
      "Certificate": "UL/IEC",
      "Current Rating": "5 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43206",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43208",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43210",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43215",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43220",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43225",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43230",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43235",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43240",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42301",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42302",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42303",
      "Certificate": "UL/IEC",
      "Current Rating": "3 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42304",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42305",
      "Certificate": "UL/IEC",
      "Current Rating": "5 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42306",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42308",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42310",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42315",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42320",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42325",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42330",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42335",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42340",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42345",
      "Certificate": "UL/IEC",
      "Current Rating": "45 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42350",
      "Certificate": "UL/IEC",
      "Current Rating": "50 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F42363",
      "Certificate": "UL/IEC",
      "Current Rating": "63 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43301",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43302",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43303",
      "Certificate": "UL/IEC",
      "Current Rating": "3 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43304",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43305",
      "Certificate": "UL/IEC",
      "Current Rating": "5 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43306",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43308",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43310",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43315",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43320",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43325",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43330",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43335",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F43340",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F54101",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54102",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54104",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54106",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54108",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54110",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54115",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54120",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54125",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54130",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54135",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54140",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54145",
      "Certificate": "UL/IEC",
      "Current Rating": "45 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54150",
      "Certificate": "UL/IEC",
      "Current Rating": "50 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F54163",
      "Certificate": "UL/IEC",
      "Current Rating": "63 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "Z Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52101",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52102",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52104",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52106",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52108",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52110",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52115",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52120",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52125",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52130",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52135",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52140",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52145",
      "Certificate": "UL/IEC",
      "Current Rating": "45 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52150",
      "Certificate": "UL/IEC",
      "Current Rating": "50 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52163",
      "Certificate": "UL/IEC",
      "Current Rating": "63 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53101",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53102",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53104",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53106",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53108",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53110",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53115",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53120",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53125",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53130",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53135",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53140",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52201",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52202",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52204",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52206",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52208",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52210",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52215",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52220",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52225",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52230",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52235",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52240",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52245",
      "Certificate": "UL/IEC",
      "Current Rating": "45 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52250",
      "Certificate": "UL/IEC",
      "Current Rating": "50 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52263",
      "Certificate": "UL/IEC",
      "Current Rating": "63 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53201",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53202",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53204",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53206",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53208",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53210",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53215",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53220",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53225",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53230",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53235",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53240",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52301",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52302",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52304",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52306",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52308",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52310",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52315",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52320",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52325",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52330",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52335",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52340",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52345",
      "Certificate": "UL/IEC",
      "Current Rating": "45 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52350",
      "Certificate": "UL/IEC",
      "Current Rating": "50 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F52363",
      "Certificate": "UL/IEC",
      "Current Rating": "63 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53301",
      "Certificate": "UL/IEC",
      "Current Rating": "1 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53302",
      "Certificate": "UL/IEC",
      "Current Rating": "2 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53304",
      "Certificate": "UL/IEC",
      "Current Rating": "4 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53306",
      "Certificate": "UL/IEC",
      "Current Rating": "6 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53308",
      "Certificate": "UL/IEC",
      "Current Rating": "8 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53310",
      "Certificate": "UL/IEC",
      "Current Rating": "10 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53315",
      "Certificate": "UL/IEC",
      "Current Rating": "15 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53320",
      "Certificate": "UL/IEC",
      "Current Rating": "20 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53325",
      "Certificate": "UL/IEC",
      "Current Rating": "25 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53330",
      "Certificate": "UL/IEC",
      "Current Rating": "30 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53335",
      "Certificate": "UL/IEC",
      "Current Rating": "35 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F53340",
      "Certificate": "UL/IEC",
      "Current Rating": "40 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D/K Curve",
      "Connection Type": "Ring-tongue Terminal"
    },
    {
      "MCB Reference": "M9F10101",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10102",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10103",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10104",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10106",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10110",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10113",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10116",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10120",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10125",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10132",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10140",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10150",
      "Certificate": "IEC",
      "Current Rating": "50 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10163",
      "Certificate": "IEC",
      "Current Rating": "63 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11101",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11102",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11103",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11104",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11106",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11110",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11113",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11116",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11120",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11125",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11132",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11140",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11150",
      "Certificate": "IEC",
      "Current Rating": "50 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11163",
      "Certificate": "IEC",
      "Current Rating": "63 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12101",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12102",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12103",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12104",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12106",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12110",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12113",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12116",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12120",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12125",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12132",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12140",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "1 Pole",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10201",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10202",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10203",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10204",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10206",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10210",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10213",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10216",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10220",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10225",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10232",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10240",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10250",
      "Certificate": "IEC",
      "Current Rating": "50 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10263",
      "Certificate": "IEC",
      "Current Rating": "63 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11201",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11202",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11203",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11204",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11206",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11210",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11213",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11216",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11220",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11225",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11232",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11240",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11250",
      "Certificate": "IEC",
      "Current Rating": "50 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11263",
      "Certificate": "IEC",
      "Current Rating": "63 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12201",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12202",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12203",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12204",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12206",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12210",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12213",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12216",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12220",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12225",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12232",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12240",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "2 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10301",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10302",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10303",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10304",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10306",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10310",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10313",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10316",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10320",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10325",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10332",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10340",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10350",
      "Certificate": "IEC",
      "Current Rating": "50 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10363",
      "Certificate": "IEC",
      "Current Rating": "63 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11301",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11302",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11303",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11304",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11306",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11310",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11313",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11316",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11320",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11325",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11332",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11340",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11350",
      "Certificate": "IEC",
      "Current Rating": "50 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11363",
      "Certificate": "IEC",
      "Current Rating": "63 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12301",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12302",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12303",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12304",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12306",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12310",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12313",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12316",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12320",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12325",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12332",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12340",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "3 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10401",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10402",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10403",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10404",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10406",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10410",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10413",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10416",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10420",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10425",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10432",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10440",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10450",
      "Certificate": "IEC",
      "Current Rating": "50 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F10463",
      "Certificate": "IEC",
      "Current Rating": "63 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "B Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11401",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11402",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11403",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11404",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11406",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11410",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11413",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11416",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11420",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11425",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11432",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11440",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11450",
      "Certificate": "IEC",
      "Current Rating": "50 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F11463",
      "Certificate": "IEC",
      "Current Rating": "63 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "C Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12401",
      "Certificate": "IEC",
      "Current Rating": "1 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12402",
      "Certificate": "IEC",
      "Current Rating": "2 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12403",
      "Certificate": "IEC",
      "Current Rating": "3 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12404",
      "Certificate": "IEC",
      "Current Rating": "4 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12406",
      "Certificate": "IEC",
      "Current Rating": "6 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12410",
      "Certificate": "IEC",
      "Current Rating": "10 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12413",
      "Certificate": "IEC",
      "Current Rating": "13 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12416",
      "Certificate": "IEC",
      "Current Rating": "16 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12420",
      "Certificate": "IEC",
      "Current Rating": "20 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12425",
      "Certificate": "IEC",
      "Current Rating": "25 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12432",
      "Certificate": "IEC",
      "Current Rating": "32 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    },
    {
      "MCB Reference": "M9F12440",
      "Certificate": "IEC",
      "Current Rating": "40 A",
      "No of Poles": "4 Poles",
      "Breaking Curve": "D Curve",
      "Connection Type": "Tunnel Terminal"
    }
  ]
};

export default definition;
