
const definition = {
  columnOrder: {
    "Drive Reference": 1,
    "Breaker Reference": 2,
    "Contactor Reference": 3,
    "Voltage Phase": 4,
    "Operating Voltage": 5,
    "Motor Power (kW)": 6,
    "Motor Power (hp)": 7,
    "Protection Level": 8,
    "Type": 9,
    "Coil Voltage": 10
  },
  data: [
    {
      "Drive Reference": "ATV320U02M2B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2B",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2W",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2WS",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U02M3C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U04M3C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U06M3C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U07M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U11M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U15M3C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U22M3C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U30M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U40M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U55M3C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U75M3C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D18B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320D11M3C",
      "Breaker Reference": "GV3P65",
      "Contactor Reference": "LC1D25B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320D15M3C",
      "Breaker Reference": "GV3P73",
      "Contactor Reference": "LC1D32B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4B",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4W",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4WS",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4B",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4C",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4W",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4WS",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4B",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4B",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U07S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U15S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U22S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U40S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U55S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U75S6C",
      "Breaker Reference": "GV3P18",
      "Contactor Reference": "LC1D09B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320D11S6C",
      "Breaker Reference": "GV3P25",
      "Contactor Reference": "LC1D18B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320D15S6C",
      "Breaker Reference": "GV3P32",
      "Contactor Reference": "LC1D25B7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "24 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2B",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2W",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2WS",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U02M3C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U04M3C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U06M3C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U07M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U11M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U15M3C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U22M3C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U30M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U40M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U55M3C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U75M3C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D18E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320D11M3C",
      "Breaker Reference": "GV3P65",
      "Contactor Reference": "LC1D25E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320D15M3C",
      "Breaker Reference": "GV3P73",
      "Contactor Reference": "LC1D32E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4B",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4W",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4WS",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4B",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4C",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4W",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4WS",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4B",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4B",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U07S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U15S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U22S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U40S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U55S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U75S6C",
      "Breaker Reference": "GV3P18",
      "Contactor Reference": "LC1D09E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320D11S6C",
      "Breaker Reference": "GV3P25",
      "Contactor Reference": "LC1D18E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320D15S6C",
      "Breaker Reference": "GV3P32",
      "Contactor Reference": "LC1D25E7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "48 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2B",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2W",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2WS",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U02M3C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U04M3C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U06M3C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U07M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U11M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U15M3C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U22M3C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U30M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U40M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U55M3C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U75M3C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D18F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320D11M3C",
      "Breaker Reference": "GV3P65",
      "Contactor Reference": "LC1D25F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320D15M3C",
      "Breaker Reference": "GV3P73",
      "Contactor Reference": "LC1D32F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4B",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4W",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4WS",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4B",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4C",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4W",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4WS",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4B",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4B",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U07S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U15S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U22S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U40S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U55S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U75S6C",
      "Breaker Reference": "GV3P18",
      "Contactor Reference": "LC1D09F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320D11S6C",
      "Breaker Reference": "GV3P25",
      "Contactor Reference": "LC1D18F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320D15S6C",
      "Breaker Reference": "GV3P32",
      "Contactor Reference": "LC1D25F7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "110 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2B",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2W",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2WS",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U02M3C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U04M3C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U06M3C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U07M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U11M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U15M3C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U22M3C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U30M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U40M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U55M3C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U75M3C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D18FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320D11M3C",
      "Breaker Reference": "GV3P65",
      "Contactor Reference": "LC1D25FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320D15M3C",
      "Breaker Reference": "GV3P73",
      "Contactor Reference": "LC1D32FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4B",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4W",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4WS",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4B",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4C",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4W",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4WS",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4B",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4B",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U07S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U15S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U22S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U40S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U55S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U75S6C",
      "Breaker Reference": "GV3P18",
      "Contactor Reference": "LC1D09FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320D11S6C",
      "Breaker Reference": "GV3P25",
      "Contactor Reference": "LC1D18FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320D15S6C",
      "Breaker Reference": "GV3P32",
      "Contactor Reference": "LC1D25FE7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "115 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2B",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2W",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2WS",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U02M3C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U04M3C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U06M3C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U07M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U11M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U15M3C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U22M3C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U30M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U40M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U55M3C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U75M3C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D18M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320D11M3C",
      "Breaker Reference": "GV3P65",
      "Contactor Reference": "LC1D25M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320D15M3C",
      "Breaker Reference": "GV3P73",
      "Contactor Reference": "LC1D32M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4B",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4W",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4WS",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4B",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4C",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4W",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4WS",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4B",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4B",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U07S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U15S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U22S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U40S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U55S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U75S6C",
      "Breaker Reference": "GV3P18",
      "Contactor Reference": "LC1D09M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320D11S6C",
      "Breaker Reference": "GV3P25",
      "Contactor Reference": "LC1D18M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320D15S6C",
      "Breaker Reference": "GV3P32",
      "Contactor Reference": "LC1D25M7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "220 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2B",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2W",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2WS",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U02M3C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U04M3C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U06M3C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U07M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U11M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U15M3C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U22M3C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U30M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U40M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U55M3C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U75M3C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D18P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320D11M3C",
      "Breaker Reference": "GV3P65",
      "Contactor Reference": "LC1D25P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320D15M3C",
      "Breaker Reference": "GV3P73",
      "Contactor Reference": "LC1D32P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4B",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4W",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4WS",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4B",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4C",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4W",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4WS",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4B",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4B",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U07S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U15S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U22S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U40S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U55S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U75S6C",
      "Breaker Reference": "GV3P18",
      "Contactor Reference": "LC1D09P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320D11S6C",
      "Breaker Reference": "GV3P25",
      "Contactor Reference": "LC1D18P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320D15S6C",
      "Breaker Reference": "GV3P32",
      "Contactor Reference": "LC1D25P7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "230 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2B",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2W",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2WS",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U02M3C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U04M3C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U06M3C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U07M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U11M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U15M3C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U22M3C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U30M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U40M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U55M3C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U75M3C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D18U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320D11M3C",
      "Breaker Reference": "GV3P65",
      "Contactor Reference": "LC1D25U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320D15M3C",
      "Breaker Reference": "GV3P73",
      "Contactor Reference": "LC1D32U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4B",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4W",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4WS",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4B",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4C",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4W",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4WS",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4B",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4B",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U07S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U15S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U22S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U40S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U55S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U75S6C",
      "Breaker Reference": "GV3P18",
      "Contactor Reference": "LC1D09U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320D11S6C",
      "Breaker Reference": "GV3P25",
      "Contactor Reference": "LC1D18U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320D15S6C",
      "Breaker Reference": "GV3P32",
      "Contactor Reference": "LC1D25U7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "240 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U02M2WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U04M2WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U06M2WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U07M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U11M2WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2B",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2W",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U15M2WS",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U22M2WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "1-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U02M3C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.18 kW",
      "Motor Power (hp)": "0.25 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U04M3C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U06M3C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U07M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U11M3C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U15M3C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U22M3C",
      "Breaker Reference": "GV2P20",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U30M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U40M3C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U55M3C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U75M3C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D18V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320D11M3C",
      "Breaker Reference": "GV3P65",
      "Contactor Reference": "LC1D25V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320D15M3C",
      "Breaker Reference": "GV3P73",
      "Contactor Reference": "LC1D32V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "200-240 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4B",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4C",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4W",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U04N4WS",
      "Breaker Reference": "GV2P07",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.37 kW",
      "Motor Power (hp)": "0.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U06N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.55 kW",
      "Motor Power (hp)": "0.75 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4B",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4C",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4W",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U07N4WS",
      "Breaker Reference": "GV2P08",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4B",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4C",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4W",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U11N4WS",
      "Breaker Reference": "GV2P10",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.1 kW",
      "Motor Power (hp)": "1.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U15N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4B",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4C",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4W",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U22N4WS",
      "Breaker Reference": "GV2P14",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U30N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "3 kW",
      "Motor Power (hp)": "4 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4B",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4C",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4W",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U40N4WS",
      "Breaker Reference": "GV2P16",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4B",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4C",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4W",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U55N4WS",
      "Breaker Reference": "GV2P22",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4B",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4C",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4W",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "Without Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U75N4WS",
      "Breaker Reference": "GV2P32",
      "Contactor Reference": "LC1D18V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 65 / 66",
      "Type": "With Safety Switch Disconnector",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4B",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320D11N4C",
      "Breaker Reference": "GV3P40",
      "Contactor Reference": "LC1D25V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4B",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Book Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320D15N4C",
      "Breaker Reference": "GV3P50",
      "Contactor Reference": "LC1D32V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "380-500 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U07S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "0.75 kW",
      "Motor Power (hp)": "1 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U15S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "1.5 kW",
      "Motor Power (hp)": "2 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U22S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "2.2 kW",
      "Motor Power (hp)": "3 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U40S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "4 kW",
      "Motor Power (hp)": "5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U55S6C",
      "Breaker Reference": "GV3P13",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "5.5 kW",
      "Motor Power (hp)": "7.5 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320U75S6C",
      "Breaker Reference": "GV3P18",
      "Contactor Reference": "LC1D09V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "7.5 kW",
      "Motor Power (hp)": "10 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320D11S6C",
      "Breaker Reference": "GV3P25",
      "Contactor Reference": "LC1D18V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "11 kW",
      "Motor Power (hp)": "15 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    },
    {
      "Drive Reference": "ATV320D15S6C",
      "Breaker Reference": "GV3P32",
      "Contactor Reference": "LC1D25V7",
      "Voltage Phase": "3-Phase",
      "Operating Voltage": "525-600 VAC",
      "Motor Power (kW)": "15 kW",
      "Motor Power (hp)": "20 hp",
      "Protection Level": "IP 20",
      "Type": "Compact Format",
      "Coil Voltage": "400 VAC"
    }
  ]
}
export default definition;