const definition = {
  columnOrder: {
    "Safety Switch Reference": 1,
    "Safety Module Refernce": 2,
    "Switch Material": 3,
    "Emergency Release": 4,
    "Locking Activation": 5,
    "Auxiliary Contact": 6,
    "Connection Type": 7,
    "Safety Module Connection Type": 8
  },
  data: [
    {
      "Safety Switch Reference": "XCSLF2525312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2725312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "3 NC + 1 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2727312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2NC + 4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3838312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2525512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2725512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "3 NC + 1 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2727512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2NC + 4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3838512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2525313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2725313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "3 NC + 1 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2727313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2NC + 4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3838313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2525513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2725513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "3 NC + 1 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2727513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2NC + 4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3838513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF353531M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF373731M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF383831M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF353551M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF373751M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF383851M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535412",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737412",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737612",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737612",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535413",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737413",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737613",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737613",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF353541M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF373741M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF353561M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF373761M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2525312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2727312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3535312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3737312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3838312",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2525512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2727512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3535512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3737512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3838512",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2525313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2727313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3535313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3737313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3838313",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2525513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2727513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3535513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3737513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3838513",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE353531M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE373731M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE353551M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE373751M3",
      "Safety Module Refernce": "XPSAC5121",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Integrated Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2525312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2725312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "3 NC + 1 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2727312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2NC + 4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3838312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2525512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2725512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "3 NC + 1 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2727512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2NC + 4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3838512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2525313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2725313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "3 NC + 1 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2727313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2NC + 4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3838313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2525513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2725513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "3 NC + 1 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF2727513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2NC + 4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3838513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF353531M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF373731M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF383831M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF353551M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF373751M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF383851M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535412",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737412",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737612",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737612",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3535413",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737413",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737613",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF3737613",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF353541M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF373741M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Turn Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF353561M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLF373761M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Metal",
      "Emergency Release": "With Key Release",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2525312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2727312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3535312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3737312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3838312",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2525512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2727512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3535512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3737512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3838512",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "M20 x 1.5 Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2525313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2727313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3535313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3737313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3838313",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2525513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE2727513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3535513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3737513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE3838513",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "6 NC",
      "Connection Type": "1/2\" NPT Tapped",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE353531M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE373731M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "De-energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE353551M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "2 NC + 4 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    },
    {
      "Safety Switch Reference": "XCSLE373751M3",
      "Safety Module Refernce": "XPSAC5121P",
      "Switch Material": "Plastic",
      "Emergency Release": "Without",
      "Locking Activation": "Energization",
      "Auxiliary Contact": "4 NC + 2 NO",
      "Connection Type": "M23 Connector",
      "Safety Module Connection Type": "Removable Terminal Block"
    }
  ]
};

export default definition;